<template>
  <div>
    <v-card>
      <v-card-title>
        <data-avatar
          :icon="assignment.icon"
          :color="assignment.color"
        ></data-avatar>
        <span class="ml-4">{{ assignment.title }}</span>
        <v-spacer></v-spacer>
        <v-chip
          v-if="usedInDetailView || assignment.analysis.isShown"
          label
          v-bind="attrs"
          v-on="on"
          class="mr-2"
        >
          <v-icon small left>mdi-dumbbell</v-icon>
          <span>{{ getPriorityText(assignment.priorityScore) }}</span>
        </v-chip>
        <v-tooltip
          v-if="usedInDetailView || assignment.analysis.isShown"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip label v-bind="attrs" v-on="on" class="mr-2">
              <v-icon small left>mdi-briefcase-upload-outline</v-icon>
              <span>{{ assignment.workloadScore }}</span>
            </v-chip>
          </template>
          <span>Aufwandswert</span>
        </v-tooltip>
        <assignment-status-chip
          v-if="usedInDetailView || assignment.analysis.isShown"
          :count="assignment.assignees.length"
          :minThreshold="assignment.analysis.minAssignees"
          :maxThreshold="assignment.analysis.maxAssignees"
        ></assignment-status-chip>
      </v-card-title>

      <v-container>
        <v-row class="match-height">
          <v-col
            cols="12"
            :md="
              assignment.contact.emails.length > 0 ||
              assignment.contact.links.length > 0
                ? '8'
                : '12'
            "
          >
            <v-card v-if="assignment.description" outlined class="mb-4"
              ><v-card-subtitle class="subtitle-2"
                >Tätigkeitsbeschreibung</v-card-subtitle
              >
              <v-card-text> {{ assignment.description }}</v-card-text></v-card
            >
            <v-card outlined class="mb-4"
              ><v-card-subtitle class="subtitle-2">Zugewiesen</v-card-subtitle>
              <v-data-iterator
                :items="assignment.assignees"
                sort-by="isLeader"
                sort-desc
                hide-default-footer
                no-data-text="Diesem Aufgabengebiet sind keine Mitglieder zugewiesen."
              >
                <template v-slot:no-data>
                  <v-card flat>
                    <v-card-text class="pt-0"
                      >Diesem Aufgabengebiet sind keine Mitglieder
                      zugewiesen.</v-card-text
                    >
                  </v-card>
                </template>
                <template v-slot:default="props">
                  <v-card-text class="pt-0">
                    <v-row>
                      <v-col
                        v-for="assignee in props.items"
                        :key="assignee.user.uid"
                        cols="12"
                        sm="12"
                        md="4"
                      >
                        <v-card outlined>
                          <v-card-title class="subtitle-1 font-weight-medium"
                            >{{ assignee.user.displayName }}
                            <v-spacer></v-spacer>
                            <v-chip
                              v-if="assignee.isLeader"
                              color="warning"
                              label
                              small
                              >Leitung</v-chip
                            >
                          </v-card-title>
                          <v-card-subtitle>{{
                            assignee.position
                          }}</v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </template></v-data-iterator
              >
            </v-card>
          </v-col>
          <v-col
            v-if="
              assignment.contact.emails.length > 0 ||
              assignment.contact.links.length > 0
            "
            cols="12"
            md="4"
          >
            <v-card outlined>
              <v-card-subtitle class="subtitle-2">Kontakt</v-card-subtitle>
              <v-list>
                <v-list-item
                  v-for="(email, index) in assignment.contact.emails"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      <a
                        :href="`mailto:${email.adress}`"
                        class="text-decoration-none"
                        >{{ email.adress }}
                      </a>
                    </v-list-item-title>

                    <v-list-item-subtitle>{{
                      email.title
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-for="(link, index) in assignment.contact.links"
                  :key="index"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-link </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      ><a
                        :href="link.url"
                        target="_blank"
                        class="text-decoration-none"
                        >{{ getHostname(link.url) }}</a
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      link.title
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list></v-card
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import AssignmentStatusChip from "@/components/member-management/assignments/AssignmentStatusChip.vue";
export default {
  name: "assignment-detail-card",
  components: { DataAvatar, AssignmentStatusChip },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    usedInDetailView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getHostname(url) {
      return new URL(url).hostname;
    },
    getPriorityText(priority) {
      switch (priority) {
        case 1:
          return "Sehr hohe Priorität";
        case 2:
          return "Hohe Priorität";
        case 3:
          return "Mittlere Priorität";
        case 4:
          return "Geringe Priorität";
        case 5:
          return "Sehr geringe Priorität";
        default:
          break;
      }
    },
  },
};
</script>
